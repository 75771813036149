<template>
  <div v-if="facturesfournisseurs" class="flex-container non-scrollable">
    <page-title value="Factures fournisseurs validées"/>
    <tableau-facture-fournisseur-admin :missions="missions" :independents="independents" class="pr-3"
        :facturefournisseurs="facturesfournisseurs.filter(f => f.independentStatus === 'validated' && f.adminStatus !== 'validate')"
        @reload="loadData"/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import TableauFactureFournisseurAdmin from "@/components/NDF/TableauFactureFournisseurAdmin";
import PageTitle from "@/components/PageTitle.vue";

export default {
  name: "NDFFFValidees",
  components: {PageTitle, TableauFactureFournisseurAdmin},
  data () {
    return {
      missions: null,
      independents: null,
      facturesfournisseurs: null,
    }
  },
  computed: {
    ...mapState({
    }),
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    async loadData () {
      this.$store.state.misc.loading = true
      this.facturesfournisseurs = await this.$store.dispatch('facturefournisseur/getAllFacturefournisseurs')
      this.missions = await this.$store.dispatch('mission/getAllMissionsForEmployees')
      this.independents = await this.$store.dispatch('independent/getAllIndependents')
      this.$store.state.misc.loading = false
    }
  }
}
</script>

<style scoped>

</style>
